const base = process.env.REACT_APP_BASEURL;
const dashboardUrl = "/cms";
const authUrl = "/auth";
const myParcelUrl = process.env.REACT_APP_MY_PARCEL_ENV_URL;
const emailServiceUrl = process.env.REACT_APP_MY_PARCEL_ENV_URL;
const paymentAPI = process.env.REACT_APP_API_URL + "/api/payment/mollie";
const apiUrl = process.env.REACT_APP_API_URL;
const mailService = process.env.REACT_APP_API_URL + "/api/email";
const hubooService = process.env.REACT_APP_API_URL + "/api/huboo";
const picqerService = process.env.REACT_APP_API_URL + "/api/picqer";

module.exports = {
  base: base,
  dashboard: dashboardUrl,
  auth: authUrl,
  login: authUrl + "/login",
  forgotPassword: authUrl + "/forgot-password",
  acceptInvite: authUrl + "/invite/",
  products: dashboardUrl + "/products",
  productList: dashboardUrl + "/products/product-list",
  addProduct: dashboardUrl + "/products/add-product",
  editProduct: dashboardUrl + "/products/edit-product/",
  featuredProducts: dashboardUrl + "/products/featured",
  discounts: dashboardUrl + "/discounts/",
  subscriptionList: dashboardUrl + "/products/subscription-list",
  addSubscription: dashboardUrl + "/products/add-subscription",
  editSubscription: dashboardUrl + "/products/edit-subscription/",
  promotions: dashboardUrl + "/discounts/promotions",
  discountCodes: dashboardUrl + "/discounts/discount-codes",
  ordersList: dashboardUrl + "/orders/orders",
  customerSubscriptionList: dashboardUrl + "/orders/subscriptions",
  customerList: dashboardUrl + "/orders/customers",
  users: dashboardUrl + "/admin/users",
  myParcelAddShipments: myParcelUrl + "/shipments",
  mailTrackAndTrace: emailServiceUrl + "/myparcel/order/track",
  mollie_cancel_subscription: paymentAPI + "/subscription/cancel",
  reports: dashboardUrl + "/reports",
  klaviyo_add_member: apiUrl + "/api/klaviyo/lists/add_member",
  order_invoice: mailService + "/order/invoice",
  sendInvite: mailService + "/invite/cms",
  hubooOrder: hubooService + "/order",
  picqerOrder: picqerService + "/order",
  picqerSubscriptions: picqerService + "/subscriptions",
  picqerProduct: picqerService + "/product",
};
